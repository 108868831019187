import React from 'react';

import { useTranslation } from '@wix/yoshi-flow-editor';

import { DialogProps } from 'wix-ui-tpa';

import { ModalV2 } from 'common/components/Modal/ModalV2';
import { Search } from 'common/components/Search';

import { SELECT_GROUP_MODAL_ROOT_DATA_HOOK } from './dataHooks';

import { JoinedGroups } from './JoinedGroups';

import { st, classes } from './SelectGroupModal.st.css';
import { useJoinedGroups } from 'Groups/Widget/hooks/useJoinedGroups';

interface SelectGroupsModalProps extends DialogProps {
  onSelect(groupId: string): void;
}

export const SelectGroupModal: React.FC<SelectGroupsModalProps> = ({
  onSelect,
  ...restProps
}) => {
  const { t } = useTranslation();

  const [query, setQuery] = React.useState<string>('');
  const { queryGroups, groups, loading, total } = useJoinedGroups();

  React.useEffect(() => {
    if (restProps.isOpen) {
      queryGroups(query);
    }
  }, [restProps.isOpen, query]);

  React.useEffect(() => {
    // clean query if close modal
    if (!restProps.isOpen) {
      setQuery('');
    }
  }, [restProps.isOpen]);

  React.useEffect(() => {
    if (groups.length === 1) {
      const [group] = groups;

      onSelect(group.id as string);
    }
  }, [JSON.stringify(groups)]);

  return (
    <ModalV2
      {...restProps}
      className={st(classes.root)}
      data-hook={SELECT_GROUP_MODAL_ROOT_DATA_HOOK}
    >
      <ModalV2.Title>{t('groups-web.select-group-modal.title')}</ModalV2.Title>
      <ModalV2.Content>
        <Search
          withBorder={true}
          forceOpen={true}
          withCloseButton={true}
          placeholder={t('groups-web.select-group-modal.search')}
          onChange={handleSearchQueryChange}
          className={classes.search}
        />
        <div className={classes.groups} data-loading={loading}>
          <JoinedGroups
            query={query}
            groups={groups}
            loading={loading}
            total={total}
            onSelect={onSelect}
            loadMore={() => queryGroups(query, groups.length)}
          />
        </div>
      </ModalV2.Content>
    </ModalV2>
  );

  function handleSearchQueryChange(groupName: string) {
    setQuery(groupName);
  }
};
