import React from 'react';
import { useSelector } from 'react-redux';

import { EditorEventsProvider } from 'wix-rich-content-editor-common/dist/lib/EditorEventsContext';
import { DraftContent } from '@wix/ricos-common';
import { useBi, useTranslation } from '@wix/yoshi-flow-editor';
import { useSettings } from '@wix/tpa-settings/react';
import {
  groupCreatePostClick,
  groupFeedView,
  groupInviteMembersOptionClicked,
} from '@wix/bi-logger-groups/v2';

import { BIUserEntry } from 'common/bi-logger/types';
import { useController } from 'common/context/controller';
import { EmptyState } from 'common/components/EmptyState';

import { selectFeedItems, selectFeedStatuses } from 'store/feed/selectors';
import { selectGroupsMeta } from 'store/groups/selectors';
import { selectIsSiteAdmin } from 'store/application/selectors';

import { PostEditorModal } from 'Group/Widget/DiscussionPage/GroupFeed/PostEditorModal';
import { FeedItemList } from 'Group/Widget/DiscussionPage/GroupFeed/FeedItemList';
import { CreatePost } from 'Group/Widget/DiscussionPage/GroupFeed/CreatePost';
import { InviteMembersModal } from 'Group/Widget/Header/InviteMembers/InviteMembersModal';

import settingsParams from '../../../../settingsParams';

import { InviteMembersCard } from '../InviteMembersCard';
import { SelectGroupModal } from './SelectGroupModal';

import { selectShouldShowInviteMembers } from './selectors';

import { st, classes } from './CentralFeed.st.css';

type CentralFeedProps = {};

type DialogsState = {
  createPost: boolean;
  selectGroup: boolean;
  inviteMembers: boolean;
};

export const CentralFeed: React.FC<CentralFeedProps> = () => {
  const { t } = useTranslation();
  const bi = useBi();

  const { centralFeed$, feed$ } = useController();

  const settings = useSettings();

  const [selected, setSelected] = React.useState<string>();
  const actionForGroup = React.useRef<() => void>();

  const [dialogs, _setDialogs] = React.useState<DialogsState>({
    createPost: false,
    selectGroup: false,
    inviteMembers: false,
  });

  const prevDialogs = React.useRef<DialogsState>({
    createPost: false,
    selectGroup: false,
    inviteMembers: false,
  });

  // TODO: remove it when sidebar data will be in store
  const feedItems = useSelector(selectFeedItems);
  const feedStatuses = useSelector(selectFeedStatuses);
  const isSiteAdmin = useSelector(selectIsSiteAdmin);
  const meta = useSelector(selectGroupsMeta('joined'));

  const showInviteMembersCard = useSelector(selectShouldShowInviteMembers);

  const noPosts =
    feedItems?.length === 0 &&
    !feedStatuses.fetch.pending &&
    !feedStatuses.fetch.error;

  const showCreatePost =
    settings.get(settingsParams.showCreatePost) && (meta.total as number) > 0;

  React.useEffect(() => {
    bi.report(
      groupFeedView({
        container: 'groups',
        roles: isSiteAdmin ? 'admin' : 'member',
        userEntry: BIUserEntry.SITE,
      }),
    );
  }, []);

  if (noPosts) {
    return (
      <EmptyState
        title={t('groups-web.no-posts.title')}
        content={t('groups-web.no-posts.content')}
        className={st(classes.noPosts, {}, classes.feedItem)}
      />
    );
  }

  return (
    <div className={st(classes.root)}>
      {showCreatePost ? (
        <CreatePost
          onClick={handleActionForGroup(openCreatePostDialog)}
          className={classes.createPost}
        />
      ) : null}
      <FeedItemList
        showGroupName={true}
        showSuggestedGroupLabel={true}
        itemClassName={classes.feedItem}
        className={classes.feedItemList}
        onCreatePost={handleActionForGroup(openCreatePostDialog)}
        onFetch={handleFetchMore}
      />
      {showInviteMembersCard ? (
        <InviteMembersCard
          onClick={handleActionForGroup(openInviteMembersDialog)}
        />
      ) : null}
      <SelectGroupModal
        isOpen={dialogs.selectGroup}
        onClose={closeDialogs}
        onSelect={handleGroupSelect}
      />
      <InviteMembersModal
        isOpen={dialogs.inviteMembers}
        groupId={selected as string}
        onClose={closeDialogs}
      />
      {selected ? (
        <EditorEventsProvider>
          <PostEditorModal
            showGroupName={true}
            groupId={selected}
            isOpen={dialogs.createPost}
            onSubmit={handlePostCreate}
            onClose={closeDialogs}
            cancelButtonText={t('groups-web.discussion.new-post.back')}
            onCancel={handleBackButtonClick}
          />
        </EditorEventsProvider>
      ) : null}
    </div>
  );

  function handleFetchMore(cursor?: string) {
    centralFeed$.fetch(cursor);
  }

  function handleActionForGroup(cb: () => void) {
    return () => {
      actionForGroup.current = cb;
      openSelectGroupDialog();
    };
  }

  function openCreatePostDialog() {
    bi.report(
      groupCreatePostClick({
        origin: 'new_layout_groups_sidebar',
        userEntry: BIUserEntry.SITE,
      }),
    );

    setDialogs({
      createPost: true,
      selectGroup: false,
      inviteMembers: false,
    });
  }

  function openInviteMembersDialog() {
    bi.report(
      groupInviteMembersOptionClicked({
        origin: 'invite_members_sidebar',
        optionType: 'invite',
        userEntry: BIUserEntry.SITE,
      }),
    );
    setDialogs({
      createPost: false,
      selectGroup: false,
      inviteMembers: true,
    });
  }

  function openSelectGroupDialog() {
    setDialogs({
      createPost: false,
      selectGroup: true,
      inviteMembers: false,
    });
  }

  function closeDialogs() {
    setDialogs({
      createPost: false,
      selectGroup: false,
      inviteMembers: false,
    });
  }

  function handlePostCreate(content: DraftContent, topics: string[]) {
    if (selected) {
      feed$.create(selected, JSON.stringify(content), topics);
    }
  }

  function handleGroupSelect(groupId: string) {
    setSelected(groupId);
    actionForGroup?.current?.();
  }

  function handleBackButtonClick() {
    setDialogs(prevDialogs.current);
  }

  function setDialogs(nextDialogs: DialogsState) {
    _setDialogs((dialogs) => {
      prevDialogs.current = dialogs;
      return nextDialogs;
    });
  }
};
